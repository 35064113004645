.section {
    background: linear-gradient(var(--sec-bg)),url(../assets/space.jpg);
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    padding-inline: 4em;
    display: flex;
    align-items: center;
}

.section > * {
    text-align: center;
    max-width: 55ch;
}

.section-header {
    font-size: 15px;
    text-transform: uppercase;
    color: var(--clr-white);
}

.section-header span {
    display: block;
    font-size: clamp(3.5rem,6vw + 1rem,8rem);
    text-transform: uppercase;
    color: var(--clr-glass);
    margin-block: 2rem;
}
.section-status {
    font-size: 10px;
    text-transform: uppercase;
    color: var(--clr-gray);
}
.change-box {
        display: flex;
        justify-content: space-around;
        background: hsl(var(--clr-glass));
        backdrop-filter: blur(1rem);
        margin-block: 2rem;
}
.state-change-btn {
    background: none;
    border: none;
    color: var(--clr-white);
    text-transform: uppercase;
    font-size: clamp(10px,1vw + .4rem,25px);
    cursor: pointer;
    padding-block: 1.5rem;
}

.button {
    margin-block: 2rem;
}
.section-button {
    color: var(--clr-white);
    text-decoration: none;
    text-transform: uppercase;
    background: hsl(var(--clr-glass));
    backdrop-filter: blur(1rem);
    padding-block: .8em;
    padding-inline: 1rem;
}

@media (max-width:1000px) {
    .section {
        justify-content: center;
    }
}